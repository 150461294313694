import { createContext, useContext, useState} from "react";
import { PropTypes } from "prop-types";
import axios from "axios";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(() => {
    // Ini hanya dijalankan sekali saat komponen dimount
    return {
      user: JSON.parse(localStorage.getItem("user")),
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
    };
  });

  const storeLoginData = (data) => {
    const userType = data.superAdmin ? "superAdmin" : "admin";
    const { id, fullName, email, phoneNumber, image } = data[userType];
    const { accessToken, refreshToken } = data;

    const userData = {
      id,
      fullName,
      email,
      phoneNumber,
      image,
      userType,
    };

    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    setAuthState({
      user: userData,
      accessToken,
      refreshToken,
    });

    setTimeout(() => {
      const redirectPath =
        userType === "superAdmin" ? "/app/dashboard" : "/app/admin/dashboard";
      window.location.replace(redirectPath);
    }, 1000);
  };

  const updateUserData = (updatedData) => {
    const updatedUser = {
      ...authState.user,
      ...updatedData,
    };

    localStorage.setItem("user", JSON.stringify(updatedUser));
    setAuthState((prev) => ({
      ...prev,
      user: updatedUser,
    }));
  };

  const logout = () => {
    localStorage.clear();
    setAuthState({
      user: null,
      accessToken: null,
      refreshToken: null,
    });
    window.location.reload();
  };

  const refreshAccessToken = async () => {
    try {
      const response = await axios.post("https://your-api.com/refresh", {
        refreshToken: authState.refreshToken,
      });
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
        response.data;
      localStorage.setItem("accessToken", newAccessToken);
      localStorage.setItem("refreshToken", newRefreshToken);
      setAuthState((prev) => ({
        ...prev,
        accessToken: newAccessToken,
        refreshToken: newRefreshToken,
      }));
      return newAccessToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      logout();
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        storeLoginData,
        updateUserData,
        logout,
        refreshAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
